<!-- enrollment -->
<template>
  <div class="enrollment-page">
    <!--  section-info1 -->
    <section-info
      :data="formDataList"
      :renderPass="renderPass"
      :iconEdit="pageType === '1'"
      title="Programme"
      :readonly.sync="readonly"
      @submit="submitEdit"
      @cancel="handleCancel"
      @input="input"
      v-loading="loading"
    >
      <template slot="renderHtml" slot-scope="scope">
        <baseGGSelect
          v-if="scope.data.key === 'programme'"
          v-model="scope.data.value"
          :placeholder="scope.data.placeholder"
          :key="scope.data.key"
          :ref="scope.data.key"
          class="float"
        >
          <md-option
            v-for="item in scope.data.roleList"
            :key="item.value"
            :value="item.value"
            class="float"
          >
            {{ item.label }}
          </md-option>
        </baseGGSelect>
        <div
          v-else-if="scope.data.key === 'commencementDate'"
          class="float datepicker-profile"
        >
          <md-datepicker
            v-model="scope.data.value"
            :md-model-type="String"
            :key="scope.data.key"
            :ref="scope.data.key"
          >
            <label>{{ scope.data.placeholder }}</label>
          </md-datepicker>
        </div>
      </template>
    </section-info>
    <!-- section2 history -->
    <Section title="History">
      <div>
        <edoovo-table
          height="100%"
          ref="multipleTable"
          :data="tableData"
          rowKey="id"
          defaultValue="-"
          class="list-tableBox"
          v-loading="loading"
          @sortChange="_onSortChange"
        >
          <!-- ===== -->
          <!-- 空状态 -->
          <!-- ===== -->
          <div slot="empty" style="text-align: center">
            <div
              style="
                width: 213px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 12px;
                line-height: 200px;
              "
            >
              <img
                style="width: 100%"
                :src="require('@/assets/images/products/noData.svg')"
              />
            </div>
            <p style="font-size: 22px">No history yet.</p>
          </div>
          <!-- =========== -->
          <!-- 每一项column -->
          <!-- =========== -->
          <edoovo-table-column
            size="L"
            label="Date"
            prop="createTime"
            sortName="createTime"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.createTime}`"
            :tooltip="true"
          >
            {{ row.createTime }}
          </edoovo-table-column>
          <edoovo-table-column
            size="L"
            label="Status"
            prop="status"
            sortName="status"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.status}`"
          >
            {{ row.status }}
          </edoovo-table-column>
          <edoovo-table-column
            size="M"
            label="Class"
            prop="className"
            sortName="className"
          />
          <edoovo-table-column
            size="M"
            label="Location"
            prop="schoolName"
            sortName="schoolName"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.schoolName}`"
          >
            {{ row.schoolName }}
          </edoovo-table-column>
        </edoovo-table>
      </div>
    </Section>
    <imgCutModal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
    />
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import { Ajax, Utils } from "@/common";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "enrollment",
  components: {},
  data() {
    this.$material.locale.dateFormat = "dd/MM/yy";
    return {
      enrollmentSlotData: ["programme", "commencementDate"],
      snackbar: {
        isShow: false,
        content: "",
      },
      isImgCutShow: false,
      formDataList: [
        {
          placeholder: "Programme",
          key: "programme",
          float: true,
          value: "",
          roleList: [
         
          ],
          renderHtml: true,
        },
        {
          placeholder: "Commencement date",
          key: "commencementDate",
          value: null,
          renderHtml: true,
        },
      ],
      renderPass: true,
      isDisabled: true,
      readonly: true,
      tableData: [
        {
          date: "202033/33",
          status: "active",
          class: "一般",
          location: "sjsjjs",
        },
      ],
      loading: false,
      snackbar: {
        isShow: false,
        content: "",
      },
      rowId: null, // 修改学生状态用到ID
      messageObj: {
        Delete: {
          title: "Delete student ?",
          desc:
            "Once the student is deleted, you will not be able to recover the student data.",
          cancelTxt: "Cancel",
          confirmTxt: "Delete",
          snackbarContent: "Student has been deleted",
        },
        Deferred: {
          title: "Defer student ?",
          desc:
            "Once deferred, this student will move to the deferred students. Student data will remain in the system.",
          cancelTxt: "Cancel",
          confirmTxt: "Defer",
          snackbarContent: "Student has been deferred",
        },
        Enrolled: {
          title: "Return student ?",
          desc:
            "Once returned, This student will move to the enrolled students.",
          cancelTxt: "Cancel",
          confirmTxt: "Return",
          snackbarContent: "Student has been returned",
        },
        Withdrawn: {
          title: "Withdraw student ?",
          desc:
            "Once Withdrawn, the student will be removed from assigned class. The student's family users will be disconnected with the system.   ",
          cancelTxt: "Cancel",
          confirmTxt: "Withdraw",
          snackbarContent: "Student has been Withdrawn",
        },
      },
      className: sessionStorage.getItem("className"),
      locationName: sessionStorage.getItem("schoolName"),
    };
  },
  filters: {
    firstUpperCase: Utils.firstUpperCase,
  },
  computed: {
    studentId() {
      return JSON.parse(sessionStorage.getItem("studentInfo")).studentId;
    },
    pageType() {
      return JSON.parse(sessionStorage.getItem("studentInfo")).type;
    },
	...mapState({
	  user: (state) => state.user,
	}),
  },
  watch: {
    formDataList: {
      handler() {
        this.$nextTick(() => {
          let renderPass = this.enrollmentSlotData.every(
            (item) => this.$refs[item] && !!this.$refs[item].value
          );
          this.renderPass = renderPass;
        });
      },
      deep: true,
    },
  },
  methods: {
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
    _getData() {
      this.loading = true;
      Ajax.get("/usermanage/student/selectByStuId", {
        stuId: this.studentId,
      })
        .then((res) => {
          this.rowId = res.data.id;
          this.loading = false;
          this.formDataList.forEach((item) => {
            item.value = res.data[item.key];
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _getHistoryData() {
      this.loading = true;
      Ajax.get(`/usermanage/class/change/listByStudentId/${this.studentId}`)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data;
			this.tableData.forEach((item)=>{
				item.createTime=moment(item.createTime).format('DD/MM/YYYY')
			})
			setTimeout(()=>{
				console.log(this.tableData)
			},2000)
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _updateStudent() {
      let formData = {
        programme: "",
        commencementDate: "",
         id: this.studentId,
      };
      this.formDataList.forEach((item) => {
        formData[item.key] = item.value;
      });
      this.loading = true;
      Ajax.post("/usermanage/student/updateStudent", formData)
        .then((res) => {
          if (res.code !== "0000") {
            this.handleSuspend(res.message);
          }
          this.loading = false;
          this._getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    linkTo(item) {
      this.$emit("handleClick", item);
    },
    _uploadCallback(val, cc) {
      console.log(val, cc);
    },
    input(res) {
      console.log(res);
    },
    handleCancel() {
      this.readonly = true;
    },
    submitEdit() {
      this.readonly = true;
      this._updateStudent();
    },
	getProgrammeList(){
		this.loading = true;
		Ajax.post("/usermanage/setting/getList", {
			dataLevel:'student',
			dataType: "programme",
			userId: this.user.userId
		})
		  .then((res) => {
		    if (res.code !== "0000") {
		      this.handleSuspend(res.message);
		    }
		    this.loading = false;
			let a={}
			this.formDataList.forEach((item,index)=>{
				if(item.key=="programme"){
					res.data.forEach((items,index)=>{
						a={
							value:res.data[index].value,
							label:res.data[index].value
						}
						item.roleList.push(a)
					})
				}
			})
			console.log(this.formDataList)
		  })
		  .catch(() => {
		    this.loading = false;
		  });
	},
    _onSortChange() {},
    openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      }).then(() => {
        this.handleSuspend(messageObj.snackbarContent);
      });
    },
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
  },
  created() {
    this._getHistoryData();
    this._getData();
	
	this.getProgrammeList()
  },
};
</script>
<style lang='less' scoped>
/deep/.phone-number-content {
  margin-bottom: 0;
}
.section-list {
  min-height: 132px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &-header {
    height: 68px;
    padding: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    &-left {
      display: flex;
      align-items: center;
      .title {
        font-family: OpenSans-Bold;
        font-size: 22px;
        color: #202124;
      }
    }
    &-right {
    }
  }
  &-main {
    padding: 0 20px;
    margin: 20px 0 0;
    color: #202124;
    font-family: Roboto;
    font-size: 14px;
    overflow-y: auto;
    .content {
      display: flex;
      flex-wrap: wrap;
    }
    .item {
      width: 136px;
      // height: 116px;
      margin-bottom: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        margin: 9px 0;
        width: 100%;
        height: 19px;
        line-height: 19px;
        color: #1a73e8;
        font-family: Roboto-medium;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        cursor: pointer;
      }
      .connections {
        margin-bottom: 9px;
        color: #5f6368;
      }
      .btn {
        height: 24px;
        padding: 0 13px;
        border: 1px solid #1a73e8;
        border-radius: 4px;
        color: #1a73e8;
        font-family: Roboto-medium;
        cursor: pointer;
        &.text {
          color: #0b8043;
          border: 0;
          cursor: inherit;
        }
      }
      .addMenu {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        width: 88px;
        height: 88px;
        line-height: 88px;
        border: 1px solid #b4b4b4;
        border-radius: 50%;
        color: #b4b4b4;
        font-size: 34px;
        cursor: pointer;
        &:hover .not-allowed {
          cursor: not-allowed;
          background-color: #eee;
        }
      }
      .addBtn {
        font-family: Roboto;
        font-size: 10px;
        width: 64px;
        height: 22px;
        padding: 0;
        line-height: 22px;
        background-color: #6f6f6f;
        color: #fff;
        text-align: center;
        border: 0;
        border-radius: 2px;
      }
    }
  }
}
// pickup userinfo
/deep/.gg_dialog .el-dialog .el-dialog__header {
  padding-top: 10px;
}
// /deep/.ggPageTitle {
//   padding: 0 18px 0 0;
//   border: 0;
//   align-items: center;
// }
/deep/.phone-number-content {
  padding-top: 20px;
}
.userList {
  li {
    display: flex;
    font-size: 16px;
    font-family: Roboto;
    color: #202124;
    margin-bottom: 32px;
    .label {
      padding-right: 100px;
      width: 252px;
    }
  }
}
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  /deep/.float {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
}
/deep/.datepicker-profile {
  .md-icon-image {
    position: absolute;
    right: 0;
    margin: 2px auto 0;
  }
  /deep/.md-clear {
    right: 24px !important;
    top: auto;
    bottom: 3px;
    /deep/.md-icon-image {
      position: relative;
      right: auto;
      margin: 4px auto;
    }
  }
  /deep/ .md-input {
    margin-left: 0;
    color: #202124;
    font-family: Roboto;
  }
  /deep/ label {
    left: 0;
    font-size: 14px;
    color: #b4b4b4;
    font-family: Roboto;
  }
  /deep/ .md-field {
    width: 100% !important;
  }
}
</style>