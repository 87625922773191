var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"enrollment-page"},[_c('edoovo-table',{ref:"multipleTable",staticClass:"list-tableBox",attrs:{"height":"100%","data":_vm.usersData.tableData,"rowKey":"id","title":_vm.$route.query.className,"defaultValue":"-","v-loading":_vm.usersData.isLoading,"sort":_vm.usersData.sort},on:{"sortChange":_vm._onSortChange}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","margin-bottom":"12px","line-height":"200px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("No attendance info yet.")])]),_c('edoovo-pagination',{attrs:{"slot":"pagination","current-page":_vm.query.current,"page-size":_vm.query.size,"total":_vm.usersData.totalNum},on:{"size-change":_vm._pageSizeChange,"current-change":_vm._pageChange},slot:"pagination"}),(_vm.filterRenderData.length&& _vm.usersData&&_vm.usersData.tableData.length)?_c('div',{staticClass:"list-filterBox",attrs:{"slot":"filter"},slot:"filter"},[_c('base-filter',{ref:"tablefilter",attrs:{"renderData":_vm.filterRenderData,"multKeys":_vm.multKeys},on:{"filterCallback":_vm._filterCallback}})],1):_vm._e(),_c('edoovo-table-column',{attrs:{"size":"M","label":"Date","prop":"Date","sortName":"Date","getTooltipText":function (row) { return ("" + (row.date)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"color":"#1a73e8","cursor":"pointer"},on:{"click":function($event){return _vm.toDetail(row)}}},[_vm._v(" "+_vm._s(row.date)+" ")])]}}])}),_c('edoovo-table-column',{attrs:{"size":"S","label":"Attendance","prop":"checkIn","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.attendance === 0)?_c('span',[_vm._v("-")]):_vm._e(),(row.attendance === 1)?_c('i',{staticClass:"el-icon-circle-check",staticStyle:{"font-size":"24px","color":"#3db175"}}):_vm._e(),(row.attendance === 2)?_c('i',{staticClass:"el-icon-circle-close",staticStyle:{"font-size":"24px","color":"#ff7d72"}}):_vm._e()]}}])}),_c('edoovo-table-column',{attrs:{"size":"S","label":"Check in","prop":"checkIn","getTooltipText":function (row) { return ("" + (row.checkinTime?row.checkinTime:'')); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.checkinTime || "-")+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Temperature","prop":"checkinTemperature","getTooltipText":function (row) { return ("" + (row.checkinTemperature?row.checkinTemperature:'-')); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.checkinTemperature ? row.checkinTemperature+'°C': "-")+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"S","label":"In class","prop":"checkinTemperature","getTooltipText":function (row) { return ("" + (row.inClassTime?row.inClassTime:'-')); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.inClassTime ? row.inClassTime: "-")+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Temperature","prop":"checkinTemperature","getTooltipText":function (row) { return ("" + (row.inClassTemperature?row.inClassTemperature:'-')); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.inClassTemperature ? row.inClassTemperature+'°C': "-")+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"S","label":"Check out","prop":"checkOut","getTooltipText":function (row) { return ("" + (row.checkoutTime)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.checkoutTime || "-")+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Temperature","prop":"checkoutTemperature","getTooltipText":function (row) { return ("" + (row.checkoutTemperature)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.checkoutTemperature? row.checkoutTemperature+'°C': "-")+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Health check","prop":"type","align":"center","tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.healthCheckTime==0)?_c('span',[_vm._v("-")]):_c('el-tooltip',{attrs:{"popper-class":"edoovo-table-cell-tooltip","content":("" + (row.healthCheckTime>1? row.healthCheckTime+' health check records':'1 health check record'))}},[_c('i',{staticClass:"icon iconfontNew icona-SymptomsIcon_24dp",staticStyle:{"font-size":"24px","cursor":"pointer"}})])]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Remarks","prop":"type","align":"center","getTooltipText":function (row) { return ("" + (row.remarks>1? row.remarks+' remarks':'1 remark')); },"tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.remarks==0)?_c('span',[_vm._v("-")]):_c('el-tooltip',{attrs:{"popper-class":"edoovo-table-cell-tooltip","content":("" + (row.remarks>1? row.remarks+' remarks':'1 remark'))}},[_c('i',{staticClass:"icon iconfontNew icona-RemarkIcon_24dp",staticStyle:{"font-size":"24px","cursor":"pointer"}})])]}}])}),_c('edoovo-table-column',{attrs:{"size":"L"}})],1),(_vm.isImgCutShow)?_c('imgCutModal',{attrs:{"visible":_vm.isImgCutShow},on:{"update:visible":function($event){_vm.isImgCutShow=$event},"_uploadCallback":_vm._uploadCallback}}):_vm._e(),_c('Snackbar',{attrs:{"visible":_vm.snackbar.isShow,"content":_vm.snackbar.content},on:{"update:visible":function($event){return _vm.$set(_vm.snackbar, "isShow", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }