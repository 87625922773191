<!-- enrollment -->
<template>
  <div class="enrollment-page">
    <!--  section-info1 -->
    
    <!-- section2 history -->
      <edoovo-table
        height="100%"
        ref="multipleTable"
        :data="usersData.tableData"
        rowKey="id"
        :title="$route.query.className"
        defaultValue="-"
        class="list-tableBox"
        :v-loading="usersData.isLoading"
        @sortChange="_onSortChange"
        :sort="usersData.sort"  
      >
        <!-- ===== -->
        <!-- 空状态 -->
        <!-- ===== -->
        <div slot="empty" style="text-align: center;">
          <div
            style="
              width: 213px;
              text-align: center;
              border-radius: 50%;
              margin-bottom: 12px;
              line-height: 200px;
            "
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/products/noData.svg')"
            />
          </div>
          <p style="font-size: 22px">No attendance info yet.</p>
        </div>
        <!-- ======= -->
        <!-- 分页组件 -->
        <!-- ======= -->
        <edoovo-pagination
          slot="pagination"
          @size-change="_pageSizeChange"
          @current-change="_pageChange"
          :current-page="query.current"
          :page-size="query.size"
          :total="usersData.totalNum"
        />
        <!-- ====== -->
        <!-- 筛选组件 -->
        <!-- ====== -->
        <div
          v-if="filterRenderData.length&& usersData&&usersData.tableData.length"
          class="list-filterBox"
          slot="filter"
        >
          <base-filter
            ref="tablefilter"
            @filterCallback="_filterCallback"
            :renderData="filterRenderData"
            :multKeys="multKeys"
          />
        </div>
        <!-- =========== -->
        <!-- 每一项column -->
        <!-- =========== -->
        <!-- <edoovo-table-column v-slot="{ row }" size="M" label="No.">
          {{row.dataIndex+1}}
        </edoovo-table-column> -->
        <edoovo-table-column
          size="M"
          label="Date"
          prop="Date"
          sortName="Date"
          :getTooltipText="(row) => `${row.date}`"
          v-slot="{ row }"
        >
         <span style="color:#1a73e8;cursor: pointer;" @click="toDetail(row)">
           {{row.date}}
         </span>
        </edoovo-table-column>
        <edoovo-table-column
          size="S"
          label="Attendance"
          prop="checkIn"
          v-slot="{ row }"
		  align='center'
        >
         <span v-if="row.attendance === 0">-</span>
          <i
            class="el-icon-circle-check"
            style="font-size: 24px; color: #3db175"
            v-if="row.attendance === 1"
			
          ></i>
          <i
            class="el-icon-circle-close"
            style="font-size: 24px; color: #ff7d72"
            v-if="row.attendance === 2"
          ></i>
        </edoovo-table-column>
        <edoovo-table-column
          size="S"
          label="Check in"
          prop="checkIn"
          v-slot="{ row }"
          :getTooltipText="(row) => `${row.checkinTime?row.checkinTime:''}`"
        >
          {{ row.checkinTime || "-" }}
        </edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Temperature"
          prop="checkinTemperature"
          v-slot="{ row }"
          :getTooltipText="(row) => `${row.checkinTemperature?row.checkinTemperature:'-'}`"
        >
          {{ row.checkinTemperature ? row.checkinTemperature+'°C': "-" }}
        </edoovo-table-column>
		<edoovo-table-column
		  size="S"
		  label="In class"
		  prop="checkinTemperature"
		  v-slot="{ row }"
		  :getTooltipText="(row) => `${row.inClassTime?row.inClassTime:'-'}`"
		>
		  {{ row.inClassTime ? row.inClassTime: "-" }}
		</edoovo-table-column>
		<edoovo-table-column
		  size="M"
		  label="Temperature"
		  prop="checkinTemperature"
		  v-slot="{ row }"
		  :getTooltipText="(row) => `${row.inClassTemperature?row.inClassTemperature:'-'}`"
		>
		  {{ row.inClassTemperature ? row.inClassTemperature+'°C': "-" }}
		</edoovo-table-column>
        <edoovo-table-column
          size="S"
          label="Check out"
          prop="checkOut"
          v-slot="{ row }"
          :getTooltipText="(row) => `${row.checkoutTime}`"
        >
          {{ row.checkoutTime || "-" }}
        </edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Temperature"
          prop="checkoutTemperature"
          v-slot="{ row }"
          :getTooltipText="(row) => `${row.checkoutTemperature}`"
        >
          <!-- {{ row.checkoutTemperature+'°C' || "-" }} -->
		  {{ row.checkoutTemperature? row.checkoutTemperature+'°C': "-" }}
        </edoovo-table-column>
         <edoovo-table-column
        	    size="M"
        	    label="Health check"
        	    prop="type"
        	    v-slot="{ row }"
				align='center'
        		:tooltip='false'
        	  >
        	   <span v-if="row.healthCheckTime==0">-</span>
        	   
        	        		<el-tooltip popper-class="edoovo-table-cell-tooltip" :content="`${row.healthCheckTime>1? row.healthCheckTime+' health check records':'1 health check record'}`"	v-else				
        	        		>
        	        			<i
        	        			  
        	        			  class="icon iconfontNew icona-SymptomsIcon_24dp"
        	        			  style="font-size: 24px; cursor: pointer"
        	        			></i></el-tooltip>
        	  </edoovo-table-column>
        	  <edoovo-table-column
        	    size="M"
        	    label="Remarks"
        	    prop="type"
				align='center'
        	    v-slot="{ row }"
        	    :getTooltipText="(row) => `${row.remarks>1? row.remarks+' remarks':'1 remark'}`"
        		:tooltip='false'
        	  >
        	   <span v-if="row.remarks==0">-</span>
        	   
        	        		<el-tooltip popper-class="edoovo-table-cell-tooltip" :content="`${row.remarks>1? row.remarks+' remarks':'1 remark'}`"	v-else
        	        		><i
        	     
        	     class="icon iconfontNew icona-RemarkIcon_24dp"
        	     style="font-size: 24px; cursor: pointer"
        	   ></i></el-tooltip>
        	  </edoovo-table-column>
        <edoovo-table-column size="L"> </edoovo-table-column>
        <!-- ====== -->
        <!-- action -->
        <!-- ====== -->
      
      </edoovo-table>
    <imgCutModal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
    />
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import { Ajax, Utils } from "@/common";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "enrollment",
  components: {},
  data() {
    // this.$material.locale.dateFormat = "dd/MM/yy";
    return {
       filterRenderData: [
        {   title: "Date",filterKey: "date", type: "date" },
        {
          title: "Attendance",
          filterKey: "status",
          type: "radio",
          radioData: [
            { key: "Absent", value: 2 },
            { key: "Present", value: 1 },
          ],
        },
        // {
        //   title: "Symptoms",
        //   filterKey: "symptoms",
        //   type: "checkbox",
        //   checkboxData: [
        //     { key: "Cough", value: "Cough" },
        //     { key: "Runny Nose", value: "Runny Nose" }
        //   ]
        // }
      ],
      enrollmentSlotData: ["programme", "commencementDate"],
      snackbar: {
        isShow: false,
        content: "",
      },
      isImgCutShow: false,
      formDataList: [
        {
          placeholder: "Programme",
          key: "programme",
          float: true,
          value: "",
          roleList: [
         
          ],
          renderHtml: true,
        },
        {
          placeholder: "Commencement date",
          key: "commencementDate",
          value: null,
          renderHtml: true,
        },
      ],
      renderPass: true,
      isDisabled: true,
      readonly: true,
      tableData: [
        {
          date: "202033/33",
          status: "active",
          class: "一般",
          location: "sjsjjs",
        },
      ],
      loading: false,
      snackbar: {
        isShow: false,
        content: "",
      },
	  healthCheckList:[],
      rowId: null, // 修改学生状态用到ID
      messageObj: {
        Delete: {
          title: "Delete student ?",
          desc:
            "Once the student is deleted, you will not be able to recover the student data.",
          cancelTxt: "Cancel",
          confirmTxt: "Delete",
          snackbarContent: "Student has been deleted",
        },
        Deferred: {
          title: "Defer student ?",
          desc:
            "Once deferred, this student will move to the deferred students. Student data will remain in the system.",
          cancelTxt: "Cancel",
          confirmTxt: "Defer",
          snackbarContent: "Student has been deferred",
        },
        Enrolled: {
          title: "Return student ?",
          desc:
            "Once returned, This student will move to the enrolled students.",
          cancelTxt: "Cancel",
          confirmTxt: "Return",
          snackbarContent: "Student has been returned",
        },
        Withdrawn: {
          title: "Withdraw student ?",
          desc:
            "Once Withdrawn, the student will be removed from assigned class. The student's family users will be disconnected with the system.   ",
          cancelTxt: "Cancel",
          confirmTxt: "Withdraw",
          snackbarContent: "Student has been Withdrawn",
        },
      },
      className: sessionStorage.getItem("className"),
      locationName: sessionStorage.getItem("schoolName"),
      multKeys: [],
      query: {
        size: 20,
        current: 1,
        studentId:""
      },
      usersData: {
        ref: "usersTable",
        totalNum: 0,
        sort: { name: "", type: "" },
        isLoading: false,
        tableTitle: "Active Staff",
        tableData: [],
      },
    };
  },
  filters: {
    firstUpperCase: Utils.firstUpperCase,
  },
  computed: {
    studentId() {
      return JSON.parse(sessionStorage.getItem("studentInfo")).studentId;
    },
    pageType() {
      return JSON.parse(sessionStorage.getItem("studentInfo")).type;
    },
	...mapState({
	  user: (state) => state.user,
	}),
  },
  watch: {
    formDataList: {
      handler() {
        this.$nextTick(() => {
          let renderPass = this.enrollmentSlotData.every(
            (item) => this.$refs[item] && !!this.$refs[item].value
          );
          this.renderPass = renderPass;
        });
      },
      deep: true,
    },
  },
  methods: {
	  toDetail(row) {
		  console.log(row)
		  let data = JSON.parse(sessionStorage.getItem("breadcrumb"))
		  data.push({
			  	name:`${row.date}`
		  })
		  data[1].routeName="studentsInfo",
		  this.$store.commit("SET_BREADCRUMDATA",data);
	    	const {href} = this.$router.resolve({
	      name: "timeline",
	      query: {
	        id: row.id,
	        studentId: row.studentId,
	        classId: row.classId,
	  	  className:this.$route.query.className,
	  	  status:row.status,
		  date:row.date
	      },
	    });
		 window.open(href,'_blank')
	  },
      _pageChange(val) {
      this.query.current = val;
      this.getData();
    },
     _pageSizeChange(val) {
      this.query.size = val;
      this.getData();
    },
     getData() {
      this.query.studentId = this.studentId;
	  let query = this.query
	  delete query.date
	  let url = '/attendance/checkInOut/getCheckInOutListByStudentIdV1'
      Ajax.post(
       url,
        query
      ).then((res) => {
        let { records, total } = res.data;
        this.usersData.tableData = records;
		this.usersData.totalNum = res.data.total
		// 0210cy table组件中$index不知道为什么每次都是1 研究了一会无果只能手动追加了 如有好办法删除下面的循环
		this.usersData.tableData.forEach((item,index)=>{
      item.createTime = moment.utc(item.createTime).local()
      item.date = moment(item.createTime,["YYYY-MM-DD hh:mm A"]).format("DD/MM/YYYY")
			item.dataIndex=index
			if(item.checkinTime){
				    item.checkinTime = moment.utc(item.checkinTime).local().format("h:mm A")
			}
			if(item.checkoutTime){
				    item.checkoutTime = moment.utc(item.checkoutTime).local().format("h:mm A")
			}
			if(item.inClassTime && item.inClassTime!='null'){
				    item.inClassTime = moment.utc(item.inClassTime).local().format("h:mm A")
			}else{
				item.inClassTime=""
			}
		})
    
      });
    },
     _filterCallback(data) {
      let obj = {};
      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });
      this.query = {
        size: 20,
        current: 1,
        status: null,
        date: null,
        classId: this.$route.query.classId,
      };
      Object.assign(this.query, obj);
	  if(this.query.date){
		  this.query.startDate = moment(this.query.date).utc().format('YYYY-MM-DD HH:mm:ss')
	  }
      this.getData();
    },
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
    _getData() {
      this.loading = true;
      Ajax.get("/usermanage/student/selectByStuId", {
        stuId: this.studentId,
      })
        .then((res) => {
          this.rowId = res.data.id;
          this.loading = false;
          this.formDataList.forEach((item) => {
            item.value = res.data[item.key];
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _getHistoryData() {
      this.loading = true;
      Ajax.get(`/usermanage/class/change/listByStudentId/${this.studentId}`)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data;
			this.tableData.forEach((item)=>{
				item.createTime=moment(item.createTime).format('DD/MM/YYYY')
			})
			setTimeout(()=>{
				console.log(this.tableData)
			},2000)
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _updateStudent() {
      let formData = {
        programme: "",
        commencementDate: "",
         id: this.studentId,
      };
      this.formDataList.forEach((item) => {
        formData[item.key] = item.value;
      });
      this.loading = true;
      Ajax.post("/usermanage/student/updateStudent", formData)
        .then((res) => {
          if (res.code !== "0000") {
            this.handleSuspend(res.message);
          }
          this.loading = false;
          this._getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    linkTo(item) {
      this.$emit("handleClick", item);
    },
    _uploadCallback(val, cc) {
      console.log(val, cc);
    },
    input(res) {
      console.log(res);
    },
    handleCancel() {
      this.readonly = true;
    },
    submitEdit() {
      this.readonly = true;
      this._updateStudent();
    },
	getProgrammeList(){
		this.loading = true;
		Ajax.post("/usermanage/setting/getList", {
			dataLevel:'student',
			dataType: "programme",
			userId: this.user.userId
		})
		  .then((res) => {
		    if (res.code !== "0000") {
		      this.handleSuspend(res.message);
		    }
		    this.loading = false;
			let a={}
			this.formDataList.forEach((item,index)=>{
				if(item.key=="programme"){
					res.data.forEach((items,index)=>{
						a={
							value:res.data[index].value,
							label:res.data[index].value
						}
						item.roleList.push(a)
					})
				}
			})
			console.log(this.formDataList)
		  })
		  .catch(() => {
		    this.loading = false;
		  });
	},
    _onSortChange() {},
    openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      }).then(() => {
        this.handleSuspend(messageObj.snackbarContent);
      });
    },
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
	queryHealthCheckList() {
	  this.healthCheckList.splice(0, this.healthCheckList.length);
	  Ajax.post(`/usermanage/setting/getList`, {
	    dataLevel: "healthCheck",
	    dataType: "illness",
	    userId: this.user.userId,
	  }).then((res) => {
	    res.data.forEach((item) => {
	      item.key = item.value;
	      this.healthCheckList.push(item);
	    });
	  });
	  Ajax.post(`/usermanage/setting/getList`, {
	    dataLevel: "healthCheck",
	    dataType: "visualCheck",
	    userId: this.user.userId,
	  }).then((res) => {
	    res.data.forEach((item) => {
	      item.key = item.value;
	      this.healthCheckList.push(item);
	    });
	  });
	},
  },
  created() {
    // this._getHistoryData();
    // this._getData();
  this.getData();
	// this.getProgrammeList()
	this.filterRenderData.push({
	  title: "Symptoms",
	  filterKey: "symptoms",
	  type: "checkbox",
	  checkboxData: this.healthCheckList,
	});
	this.queryHealthCheckList();
  },
};
</script>
<style lang='less' scoped>
/deep/.phone-number-content {
  margin-bottom: 0;
}
.section-list {
  min-height: 132px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &-header {
    height: 68px;
    padding: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    &-left {
      display: flex;
      align-items: center;
      .title {
        font-family: OpenSans-Bold;
        font-size: 22px;
        color: #202124;
      }
    }
    &-right {
    }
  }
  &-main {
    padding: 0 20px;
    margin: 20px 0 0;
    color: #202124;
    font-family: Roboto;
    font-size: 14px;
    overflow-y: auto;
    .content {
      display: flex;
      flex-wrap: wrap;
    }
    .item {
      width: 136px;
      // height: 116px;
      margin-bottom: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        margin: 9px 0;
        width: 100%;
        height: 19px;
        line-height: 19px;
        color: #1a73e8;
        font-family: Roboto-medium;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        cursor: pointer;
      }
      .connections {
        margin-bottom: 9px;
        color: #5f6368;
      }
      .btn {
        height: 24px;
        padding: 0 13px;
        border: 1px solid #1a73e8;
        border-radius: 4px;
        color: #1a73e8;
        font-family: Roboto-medium;
        cursor: pointer;
        &.text {
          color: #0b8043;
          border: 0;
          cursor: inherit;
        }
      }
      .addMenu {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        width: 88px;
        height: 88px;
        line-height: 88px;
        border: 1px solid #b4b4b4;
        border-radius: 50%;
        color: #b4b4b4;
        font-size: 34px;
        cursor: pointer;
        &:hover .not-allowed {
          cursor: not-allowed;
          background-color: #eee;
        }
      }
      .addBtn {
        font-family: Roboto;
        font-size: 10px;
        width: 64px;
        height: 22px;
        padding: 0;
        line-height: 22px;
        background-color: #6f6f6f;
        color: #fff;
        text-align: center;
        border: 0;
        border-radius: 2px;
      }
    }
  }
}
// pickup userinfo
/deep/.gg_dialog .el-dialog .el-dialog__header {
  padding-top: 10px;
}
// /deep/.ggPageTitle {
//   padding: 0 18px 0 0;
//   border: 0;
//   align-items: center;
// }
/deep/.phone-number-content {
  padding-top: 20px;
}
.userList {
  li {
    display: flex;
    font-size: 16px;
    font-family: Roboto;
    color: #202124;
    margin-bottom: 32px;
    .label {
      padding-right: 100px;
      width: 252px;
    }
  }
}
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  /deep/.float {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
}
/deep/.datepicker-profile {
  .md-icon-image {
    position: absolute;
    right: 0;
    margin: 2px auto 0;
  }
  /deep/.md-clear {
    right: 24px !important;
    top: auto;
    bottom: 3px;
    /deep/.md-icon-image {
      position: relative;
      right: auto;
      margin: 4px auto;
    }
  }
  /deep/ .md-input {
    margin-left: 0;
    color: #202124;
    font-family: Roboto;
  }
  /deep/ label {
    left: 0;
    font-size: 14px;
    color: #b4b4b4;
    font-family: Roboto;
  }
  /deep/ .md-field {
    width: 100% !important;
  }
}
/deep/.addFilterEdit-main-formBox{
	padding: 0 10px!important;
} 
/deep/.edoovo-table-box{
	
	/* padding-top: 136px!important; */
	padding-bottom: 52px!important;
	
}
.enrollment-page{
	height: 100%;
	padding-bottom: 60px;
}
/deep/.edoovo-table-filter{
	padding: 0 18px;
}
</style>
